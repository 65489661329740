import { Box, Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CreditCardFilled from '../../assets/images/CreditCardFilled.svg';
import { loadStripe } from '@stripe/stripe-js';
import "./Settings.css"
import CircularProgress from '@mui/material/CircularProgress';
import { planPricing, stripePriceIds } from 'Billing/Constants';
import { Elements, useStripe, useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
 } from '@stripe/react-stripe-js';
import { CustomCircularProgress } from 'Components/StyledComponents';
import axios from 'axios';
import { MainAppReducer } from 'Types';
import { AuthReducer } from 'Types/AuthTypes';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`

interface PlanDetails {
  subFreq?: string;
  subTitle?: string;
  subCost?: number;
  priceId?: string;
}
interface SubscriptionPlanData {
  month: PlanDetails;
  threeMonth: PlanDetails;
  year: PlanDetails;
}
interface BillingDetailsResponse {
  hasPaymentMethodId: boolean;
  isCustomer: boolean;
  subscriptionPlanAmount: number;
  subscriptionPlanInterval: 'month' | 'year';
  trialDaysRemaining: number;
  trialEndDate: string;
  hasCoupon: boolean;
}

const getBillingDetails = async (authToken: string | undefined): Promise<BillingDetailsResponse>  => {
  const response = await axios.get(
    `${baseUrl}stripe/user-billing-details`,
    {
      headers: {'Authorization': `Bearer ${authToken}`}
    }
  )
  return response.data.details
}

const Billing = () => {
  // Needed from backend query:
  // trial days, current plan, payment method id,
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const authToken = auth.jwtToken
  const { user } = auth

  const userData = data.userData
  const userEmail = user?.attributes?.email
  const userPhoneNumber = user?.attributes?.phone_number

  const [hasPaymentMethodId, setHasPaymentMethodId] = useState<boolean|null>(null)
  const [subscriptionPlanAmount, setSubscriptionPlanAmount] = useState<number|null>(null)
  const [subscriptionPlanInterval, setSubscriptionPlanInterval] = useState<string|null>(null)
  const [trialDaysRemaining, setTrialDaysRemaining] = useState<number|null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [planSelected, setPlanSelected] = useState("")
  const [startingPlan, setStartingPlan] = useState("")
  const [planAmount, setPlanAmount] = useState<number|null>(null)
  const [errors, setErrors] = useState("")
  const [refeshBilling, setRefreshBilling] = useState(false)
  const [discountCodeValid, setDiscountCodeValid] = useState(false)

  useEffect(() => {
    getBillingDetails(authToken).then(resp => {
      console.log("response?", resp)
      setHasPaymentMethodId(resp.hasPaymentMethodId)
      setSubscriptionPlanAmount(resp.subscriptionPlanAmount)
      setSubscriptionPlanInterval(resp.subscriptionPlanInterval)
      setTrialDaysRemaining(resp.trialDaysRemaining)
      if (resp.hasPaymentMethodId == false) {
        //pass
      } else {
        setPlanSelected(resp.subscriptionPlanInterval)
        setStartingPlan(resp.subscriptionPlanInterval)
        setPlanAmount(resp.subscriptionPlanAmount)
        
        const keyMap: { [key: string]: string } = {
          month: 'monthly',
          year: 'annually'
        };

        if (["month", "year"].includes(resp.subscriptionPlanInterval)) {
          setDiscountCodeValid(resp.hasCoupon)
          setSubscriptionPlanData(subscriptionPlanData => ({
            ...subscriptionPlanData,
            [resp.subscriptionPlanInterval]: {
              ...subscriptionPlanData[resp.subscriptionPlanInterval],
              subCost: resp.subscriptionPlanAmount,
            }
          }));
        }
        
      }
      setIsLoading(false)
    }).catch(error => {
      console.log("could not get user billing details")
      throw new Error("could not get user billing details")
    })
    
  }, [refeshBilling])
  
  useEffect(() => {
    if (userData!.coupon_code) {
      setDiscountCodeValid(true)
    }
  }, [userData!.coupon_code])

  let subscriptionStatus = userData!.subscription_status
  // If user has payment method id, that means they have either finished trial and selected plan with credit card
  // Or, on this page, they've picked one of the two plans already with credit card.
  // If user switches plan and no payment method id; require cc info & say that they will be charged once trial is up.

  // If no payment method yet: Must select both new plan & add payment method (not either or)
  // And then pop up a window that will show that the user that they will be charged x amount on x date (next billing date or trial date)

  // For now; just check if they are trailing.
  // let hasPaymentMethodId = true

  let allowPlanChangeSubmit = (startingPlan != planSelected)
  let subTitle = ""
  let subFreq = ""
  let subCost = ""
  let priceId = ""
  const [subscriptionPlanData, setSubscriptionPlanData] = useState<SubscriptionPlanData>({
    "month": {
      subFreq: "month",
      subTitle: "Monthly Plan",
      subCost: discountCodeValid ? planPricing.monthly.withCode : planPricing.monthly.regular,
      priceId: stripePriceIds.monthly,
    },
    "threeMonth": {
      subFreq: "3 months",
      subTitle: "3-Month Plan",
      subCost: discountCodeValid ? planPricing.threeMonths.withCode : planPricing.threeMonths.regular,
      priceId: stripePriceIds.threeMonths,
    },
    "year": {
      subFreq: "year",
      subTitle: "Annual Plan",
      subCost: discountCodeValid ? planPricing.annually.withCode : planPricing.annually.regular,
      priceId: stripePriceIds.annually,
    },
  })

  let inputClass
  if (discountCodeValid) {
    inputClass = 'discount-input plan-container-text valid-discount'
  } else {
    inputClass = 'discount-input plan-container-text valid-discount'
  }

  useEffect(() => {
    if (!allowSubmit) {
      if (planSelected != "trial" && planSelected) {
        setAllowSubmit(true)
      }
    }
  }, [planSelected])

  const updateSubscriptionPlan = async (planSelected: string) => {
    setStartingPlan(planSelected)
    const response = await axios.patch<any>(
      `${baseUrl}stripe/update-plan`,
      { 
        customerId: userData!.stripe_customer_id,
        priceId: (subscriptionPlanData as {[key: string]: any})[planSelected].priceId,
        code: userData!.coupon_code || null,
      },
      { headers: {'Authorization': `Bearer ${authToken}`} }
    )
    if (response.data && response.data.status == "success") {
      setSubscriptionPlanAmount(response.data.details.subscriptionPlanAmount)
      setSubscriptionPlanInterval(response.data.details.subscriptionPlanInterval)
      setStartingPlan(planSelected)
    }
  }

  const sendPaymentMethodToServer = async (
    setIsLoading: any,
    stripe_customer_id: string,
    paymentMethodId: string,
    authToken: string | undefined) => {
    const response = await axios.patch<any>(
      `${baseUrl}stripe/update-plan`,
      { 
        customerId: stripe_customer_id,
        paymentMethodId: paymentMethodId,
        priceId: (subscriptionPlanData as {[key: string]: any})[planSelected].priceId,
        code: userData!.coupon_code || null, // Only pass coupon if valid (otherwise validator will fail it.)
      },
      { headers: {'Authorization': `Bearer ${authToken}`} }
    )
    const data = response.data
    if (data.error) {
      console.error(data.error)
    } else {
      setIsLoading(true)
      setRefreshBilling(true)
    }
    setIsLoading(false)
  }

  const CheckoutForm: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [cardholderName, setCardholderName] = useState("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isLoading) return
      setIsLoading(true)
      if (!stripe || !elements) {
        setIsLoading(false)
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        setIsLoading(false)
        return;
      }
      
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName,
          email: userEmail,
          phone: userPhoneNumber,
        },
      });
    
      if (error) {
        setErrors(error.message || "Payment Details Required")
        setIsLoading(false)
      } else {
        sendPaymentMethodToServer(
          setIsLoading,
          userData!.stripe_customer_id,
          paymentMethod.id, 
          authToken,
        );
      }
      
    };


    return (
      <Box sx={{ padding: '14px 0px' }}>
      <form onSubmit={handleSubmit}>
        { errors != "" &&
          <Box sx={{marginBottom: '20px', color: 'red',}}>{errors}</Box>
        }
        <Box className="payment-form-container">
            <Box className="card-container" sx={{position: 'relative', paddingLeft: '44px'}}>
              <img style={{position: 'absolute', left: '12px', top: '11px',}} src={CreditCardFilled} />
              <CardNumberElement options={CARD_NUMBER_OPTIONS} />
            </Box>
          <TextField 
            className="name-input"
            fullWidth
            placeholder="Cardholder Name"
            value={cardholderName}
            onChange={(e) => setCardholderName(e.target.value)}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', gap: '16px' }}>
            <Box className="card-container exp-cvv-container" >
              <CardExpiryElement options={CARD_EXPIRY_OPTIONS} />
            </Box>
            <Box className="card-container exp-cvv-container">
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </Box>
          </Box>
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <Button type="submit" disabled={!allowSubmit} style={{...buttonStyle2, backgroundColor: allowSubmit ? '#CA7C62' : '#767C8C'}} variant="contained">
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "SUBMIT"
            )}
          </Button>
          
        </Box>
        </Box>
      </form>
      </Box>
    )
    }

    if (isLoading) {
      return (
        <Box>
          <Box sx={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CustomCircularProgress/>
          </Box> 
        </Box>
      )
    }

    return (
      <Box sx={{height: 'calc(100vh - 200px)', overflow: 'scroll'}}>
        <Box sx={{ marginLeft: '50px', width: '650px', backgroundColor: 'black', borderRadius: '5px'}}>
          <Box sx={{color: 'white', textAlign: 'left', marginLeft: '24px', marginRight: '24px', marginTop: '20px', display: 'flex', flexDirection: 'column',}}>
            <Box sx={{marginTop: '20px', marginBottom: '20px',fontStyle: 'normal',fontWeight: '400',fontSize: '26px',letterSpacing: '0.01em',color: '#FFFFFF',fontFamily: 'Lato'}}>
              Current Plan
            </Box>

            { (subscriptionStatus == "trialing") &&
              <>
              <PlanBlock trialDaysRemaining={trialDaysRemaining} hasPaymentMethodId={hasPaymentMethodId} plan={"trial"} planSelected={planSelected} setPlanSelected={setPlanSelected} trial={true} disabled={false} inputClass={inputClass} subTitle={subTitle} subCost={subCost} subFreq={subFreq} discountCodeValid={discountCodeValid} />        
              <Box sx={{marginBottom: '20px', fontFamily: 'Lato'}}>After Trial:</Box>
              </>
            }
            <PlanBlock hasPaymentMethodId={hasPaymentMethodId} plan={"month"} planSelected={planSelected} setPlanSelected={setPlanSelected} trial={false} disabled={true} inputClass={inputClass} subTitle={subscriptionPlanData.month.subTitle} subCost={subscriptionPlanData.month.subCost} subFreq={subscriptionPlanData.month.subFreq} discountCodeValid={discountCodeValid} />
            <PlanBlock hasPaymentMethodId={hasPaymentMethodId} plan={"threeMonth"} planSelected={planSelected} setPlanSelected={setPlanSelected} trial={false} disabled={true} inputClass={inputClass} subTitle={subscriptionPlanData.threeMonth.subTitle} subCost={subscriptionPlanData.threeMonth.subCost} subFreq={subscriptionPlanData.threeMonth.subFreq} discountCodeValid={discountCodeValid} />
            <PlanBlock hasPaymentMethodId={hasPaymentMethodId} plan={"year"} planSelected={planSelected} setPlanSelected={setPlanSelected} trial={false} disabled={true} inputClass={inputClass} subTitle={subscriptionPlanData.year.subTitle} subCost={subscriptionPlanData.year.subCost} subFreq={subscriptionPlanData.year.subFreq} discountCodeValid={discountCodeValid} />

            { hasPaymentMethodId &&
              <>
              <Button onClick={() => updateSubscriptionPlan(planSelected)} disabled={!allowPlanChangeSubmit} style={{...buttonStyle2, marginTop: '20px', backgroundColor: allowPlanChangeSubmit ? '#CA7C62' : '#767C8C'}} variant="contained">
                UPDATE
              </Button>
              <Box sx={{marginBottom: '10px', marginTop: '40px', borderBottom: '1px dashed #CA7C62', width: '100%'}}/>
              </>
            }

            <Box sx={{marginTop: '20px', marginBottom: '12px',fontStyle: 'normal',fontWeight: '400',fontSize: '26px',letterSpacing: '0.01em',color: '#FFFFFF',fontFamily: 'Lato'}}>
              Payment Details
            </Box>
        
            { (hasPaymentMethodId && !showPayment) &&
              <Box sx={{marginBottom: '50px', marginTop: '50px', marginLeft: '184px'}}>
                <Button style={{...buttonStyle, letterSpacing: '1px',}} onClick={() => setShowPayment(true)} variant="contained">
                  Update Payment Details
                </Button>
              </Box>
            }

            { (!hasPaymentMethodId || showPayment) &&
              <Elements stripe={stripePromise}>
                <CheckoutForm/>
              </Elements>
            }

            { hasPaymentMethodId &&
              <Box sx={{marginTop: '30px', fontFamily: 'Lato'}}>
                If you want to cancel your subscription, please email us at <a style={{color:"#6F87F1"}} href="mailto:support@museflow.ai">support@museflow.ai</a>.
              </Box>
            }

          </Box>
        </Box>
      </Box>
    )

}

export default Billing

const PlanBlock = (props: any) => {
  const {trialDaysRemaining, hasPaymentMethodId, plan, planSelected, setPlanSelected, trial, disabled, subTitle, subCost, subFreq, discountCodeValid} = props
  
  let checked = planSelected == plan
  
  let { inputClass } = props
  if (true) {
    inputClass = inputClass + ` text-disabled`
  }
  if (checked) {
    inputClass = inputClass + " plan-container-text-selected"
  } else {
    inputClass = inputClass + ` text-billing-settings`
  }
  if (trial) {
    inputClass = inputClass + ` trial-text-billing-settings`
  }

  return (
    <Box 
      className="code-field-container"
      onClick={() => {
        setPlanSelected(plan)
      }}
      sx={{
        backgroundColor: (checked) ? '#CA7C62':'none', 
        borderRadius: '80px',
        marginBottom: '20px',
        width: '600px',
        pointerEvents: trial ? 'none' : '',
        '&:hover': {
          cursor: 'pointer',
          background: checked ? '#CA7C62' : 'rgba(35,35,35,1.0)',
        }
      }}>
      <Box className="plan-check">

        {trial &&
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M12.3232 2C6.82324 2 2.32324 6.5 2.32324 12C2.32324 17.5 6.82324 22 12.3232 22C17.8232 22 22.3232 17.5 22.3232 12C22.3232 6.5 17.8232 2 12.3232 2ZM10.3232 17L5.32324 12L6.73324 10.59L10.3232 14.17L17.9132 6.58L19.3232 8L10.3232 17Z" fill="#81C69C"/>
          </svg>
        }

        {checked &&
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M12.3232 2C6.82324 2 2.32324 6.5 2.32324 12C2.32324 17.5 6.82324 22 12.3232 22C17.8232 22 22.3232 17.5 22.3232 12C22.3232 6.5 17.8232 2 12.3232 2ZM10.3232 17L5.32324 12L6.73324 10.59L10.3232 14.17L17.9132 6.58L19.3232 8L10.3232 17Z" fill="#100D09"/>
          </svg>
        }
        { (!checked && !trial) &&
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M12.3235 20C10.2018 20 8.16692 19.1571 6.66663 17.6569C5.16634 16.1566 4.32349 14.1217 4.32349 12C4.32349 9.87827 5.16634 7.84344 6.66663 6.34315C8.16692 4.84285 10.2018 4 12.3235 4C14.4452 4 16.4801 4.84285 17.9803 6.34315C19.4806 7.84344 20.3235 9.87827 20.3235 12C20.3235 14.1217 19.4806 16.1566 17.9803 17.6569C16.4801 19.1571 14.4452 20 12.3235 20ZM12.3235 2C11.0103 2 9.70991 2.25866 8.49665 2.7612C7.2834 3.26375 6.181 4.00035 5.25242 4.92893C3.37705 6.8043 2.32349 9.34784 2.32349 12C2.32349 14.6522 3.37705 17.1957 5.25242 19.0711C6.181 19.9997 7.2834 20.7362 8.49665 21.2388C9.70991 21.7413 11.0103 22 12.3235 22C14.9757 22 17.5192 20.9464 19.3946 19.0711C21.2699 17.1957 22.3235 14.6522 22.3235 12C22.3235 10.6868 22.0648 9.38642 21.5623 8.17317C21.0597 6.95991 20.3231 5.85752 19.3946 4.92893C18.466 4.00035 17.3636 3.26375 16.1503 2.7612C14.9371 2.25866 13.6367 2 12.3235 2Z" fill="#CED0D4"/>
          </svg>
        }
      </Box>
      <TextField
        fullWidth
        className={inputClass}
        label={trial ? `Free Trial: ${trialDaysRemaining} days remaining` : `${subTitle}: $${subCost}/${subFreq}`}
        inputProps={{ readOnly: true }}
        sx={{ pointerEvents: 'none', borderColor: 'green'}}
        style={{borderColor: 'red'}}
      />
      {(discountCodeValid && !trial) &&
        <Box className="code-container" sx={{color: '#2E5B3E',  backgroundColor: '#81C69C'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <path d="M6 1.37158C3.25 1.37158 1 3.62158 1 6.37158C1 9.12158 3.25 11.3716 6 11.3716C8.75 11.3716 11 9.12158 11 6.37158C11 3.62158 8.75 1.37158 6 1.37158ZM5 8.87158L2.5 6.37158L3.205 5.66658L5 7.45658L8.795 3.66158L9.5 4.37158L5 8.87158Z" fill="#2E5B3E"/>
          </svg>
          DISCOUNT CODE APPLIED
        </Box>
      }
    </Box>
  )
}


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#ADB1B7',
      fontFamily: 'Lato, sans-serif',
      fontSize: '16px',
      fontWeight: '400',
      padding: '14px 20px',
      letterSpacing: '0.15px',
      '::placeholder': {
        color: '#ADB1B7',
        fontFamily: 'Lato, sans-serif',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0.15px',
        padding: '14px 20px',
      },
      
    },
  },
};

const CARD_NUMBER_OPTIONS = {
  ...CARD_ELEMENT_OPTIONS,
  placeholder: 'Card Number',
};

const CARD_EXPIRY_OPTIONS = {
  ...CARD_ELEMENT_OPTIONS,
  placeholder: 'Expiration Date',
};

const buttonStyle = {
  width: '266px', 
  height: '51px',
  color: '#050B13', 
  fontSize: '16px',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  background: '#CA7C62', 
  letterSpacing: '2.5px',
  fontWeight: '700',
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px'
}
const buttonStyle2 = {
  // padding: 0,
  width: '138px', 
  height: '49px',
  color: '#050B13', 
  fontSize: '16px',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  background: '#CA7C62', 
  letterSpacing: '2.5px',
  fontWeight: '700',
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px'
}
